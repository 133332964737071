/* src/pages/Home.css */
.hero {
  background: linear-gradient(135deg, rgba(25, 118, 210, 0.9) 0%, #2148f3 100%),
              url('background.jpg') no-repeat center center;
  background-size: cover;
  padding: 8rem 0;
  color: white;
  position: relative;
  overflow: hidden;
}
  
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('overlay.jpg');
  opacity: 0.1;
  z-index: 0;
}

.hero .MuiContainer-root {
  position: relative;
  z-index: 2;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  animation: fadeInUp 1s ease-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1s ease-out 0.5s;
  animation-fill-mode: both;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.cta-button {
  animation: fadeInUp 1s ease-out 1s;
  animation-fill-mode: both;
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
  .solutions, .process, .testimonials, .data-visualization, .case-studies, .awards, .tech-stack, .latest-news, .partners {
    padding: 6rem 0;
  }
  
  .solutions, .testimonials, .awards, .partners {
    background-color: #f5f5f5;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .solution-card, .testimonial-card, .case-study-card, .news-card {
    height: 100%;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 2rem;
  }
  
  .solution-card:hover, .testimonial-card:hover, .case-study-card:hover, .news-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .solution-icon {
    font-size: 3rem;
    color: #2196f3;
    margin-bottom: 1rem;
  }
  
  .quote-icon {
    font-size: 3rem;
    color: #2196f3;
    margin-bottom: 1rem;
  }
  
  .chart-container {
    padding: 2rem;
  }
  
  .award-paper {
    padding: 1.5rem;
    text-align: center;
  }
  
  .award-icon {
    font-size: 2.5rem;
    color: #ffc107;
    margin-bottom: 1rem;
  }
  
  .tech-stack-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  
  .tech-chip {
    font-size: 1rem;
  }
  
  .partner-logo {
    max-width: 150px;
    max-height: 80px;
    object-fit: contain;
  }
  
  .cta {
    background: linear-gradient(135deg, #2196f3 0%, #1976d2 100%);
    padding: 6rem 0;
  }
  
  .cta-paper {
    padding: 3rem;
    text-align: center;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 960px) {
    .hero-title {
      font-size: 2.5rem;
    }
  
    .hero-subtitle {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 600px) {
    .hero-title {
      font-size: 2rem;
    }
  
    .hero-subtitle {
      font-size: 1rem;
    }
  
    .cta-paper {
      padding: 2rem;
    }
  }

  .growth-metrics {
    padding: 6rem 0;
    background-color: #f5f5f5;
  }
  
  .metric-paper {
    padding: 2rem;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .metric-paper:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .metric-value {
    color: #2196f3;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .metric-label {
    color: #333;
  }

  .solutions {
    padding: 6rem 0;
    background-color: #f5f5f5;
  }
  
  .solution-card {
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }
  
  .solution-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .solution-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .section-title {
    margin-bottom: 3rem;
    text-align: center;
  }

.achievements {
  padding: 6rem 0;
  background-color: #f8f9fa;
}

.achievement-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.achievement-paper {
  padding: 2rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.achievement-paper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #2196f3, #21d4fd);
}

.achievement-paper:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.achievement-icon {
  font-size: 3rem;
  color: #2196f3;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.achievement-paper:hover .achievement-icon {
  transform: scale(1.1);
}

.achievement-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.achievement-description {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .achievement-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

/* src/pages/Home.css */
.hero {
  background: linear-gradient(135deg, #002fffc2 0%, #4dffb6b9 100%);
  padding: 6rem 0;
  text-align: center;
  color: white;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  animation: fadeInUp 1s ease-out;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1s ease-out 0.5s;
  animation-fill-mode: both;
}

.cta-button {
  animation: fadeInUp 1s ease-out 1s;
  animation-fill-mode: both;
}

.section {
  padding: 6rem 0;
}

.section:nth-child(even) {
  background-color: #f8f9fa;
}

.section-title {
  text-align: center;
  margin-bottom: 3rem;
  color: #333;
  font-weight: 600;
}

.card {
  height: 100%;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #2196f3, #21d4fd);
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.card-icon {
  font-size: 3rem;
  color: #2196f3;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.card:hover .card-icon {
  transform: scale(1.1);
}

.tech-stack-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.tech-chip {
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.tech-chip:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.partner-logo {
  max-width: 150px;
  max-height: 80px;
  object-fit: contain;
  transition: all 0.3s ease-in-out;
}

.partner-logo:hover {
  transform: scale(1.1);
}

.cta {
  background: linear-gradient(135deg, #0b2a63 0%, #3d61ff 100%);
  color: white;
}

.cta-paper {
  padding: 3rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 960px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 600px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .cta-paper {
    padding: 2rem;
  }
}

/* src/pages/Home.css */

/* Common styles for specified sections */
.achievements, .clients, .solutions, .case-studies, .latest-news {
  padding: 6rem 0;
  background-color: #f8f9fa;
}

.achievement-grid,
.clients-grid,
.solutions-grid,
.case-studies-grid,
.latest-news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.achievement-paper,
.client-paper,
.solution-card,
.case-study-card,
.news-card {
  padding: 2rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.achievement-paper::before,
.client-paper::before,
.solution-card::before,
.case-study-card::before,
.news-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #2196f3, #21d4fd);
}

.achievement-paper:hover,
.client-paper:hover,
.solution-card:hover,
.case-study-card:hover,
.news-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.achievement-icon,
.client-icon,
.solution-icon,
.case-study-icon,
.news-icon {
  font-size: 3rem;
  color: #2196f3;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.achievement-paper:hover .achievement-icon,
.client-paper:hover .client-icon,
.solution-card:hover .solution-icon,
.case-study-card:hover .case-study-icon,
.news-card:hover .news-icon {
  transform: scale(1.1);
}

.achievement-title,
.client-title,
.solution-title,
.case-study-title,
.news-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.achievement-description,
.client-description,
.solution-description,
.case-study-description,
.news-description {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .achievement-grid,
  .clients-grid,
  .solutions-grid,
  .case-studies-grid,
  .latest-news-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

/* Technological Expertise Section Styles */
.tech-expertise-section {
  background-color: #f5f5f5;
  background-image: 
    linear-gradient(30deg, #f0f0f0 12%, transparent 12.5%, transparent 87%, #f0f0f0 87.5%, #f0f0f0),
    linear-gradient(150deg, #f0f0f0 12%, transparent 12.5%, transparent 87%, #f0f0f0 87.5%, #f0f0f0),
    linear-gradient(30deg, #f0f0f0 12%, transparent 12.5%, transparent 87%, #f0f0f0 87.5%, #f0f0f0),
    linear-gradient(150deg, #f0f0f0 12%, transparent 12.5%, transparent 87%, #f0f0f0 87.5%, #f0f0f0),
    linear-gradient(60deg, #e0e0e0 25%, transparent 25.5%, transparent 75%, #e0e0e0 75%, #e0e0e0),
    linear-gradient(60deg, #e0e0e0 25%, transparent 25.5%, transparent 75%, #e0e0e0 75%, #e0e0e0);
  background-size: 80px 140px;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}

.section-title {
  color: #1976d2;
  font-weight: 700;
  margin-bottom: 2rem;
}

.tech-category-card {
  height: 100%;
  padding: 1.5rem;
  transition: all 0.3s ease-in-out;
}

.tech-category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.category-title {
  color: #333;
  font-weight: 600;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.category-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background-color: #1976d2;
}

.tech-chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tech-chip {
  background-color: #e3f2fd;
  color: #1976d2;
  font-weight: 500;
  transition: all 0.3s ease;
}

.tech-chip:hover {
  background-color: #1976d2;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 960px) {
  .tech-expertise-section {
    padding: 4rem 0;
  }
}

@media (max-width: 600px) {
  .tech-expertise-section {
    padding: 3rem 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .category-title {
    font-size: 1.2rem;
  }
}

/* Growth Metrics Section Styles */
.growth-metrics {
  padding: 6rem 0;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  position: relative;
  overflow: hidden;
}

.growth-metrics::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('arqnxs.svg');
  opacity: 0.1;
  z-index: 1;
}

.growth-metrics .Container-root {
  position: relative;
  z-index: 2;
}

.section-title {
  color: #1976d2;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: center;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background-color: #1976d2;
}

.metric-paper {
  padding: 2rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  background: white;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.metric-paper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(90deg, #1976d2, #64b5f6);
}

.metric-paper:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.metric-icon {
  font-size: 3rem;
  color: #1976d2;
  margin-bottom: 1rem;
}

.metric-value {
  color: #1976d2;
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.metric-label {
  color: #333;
  font-weight: 500;
}

@keyframes countUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.metric-value {
  animation: countUp 2s ease-out;
}

@media (max-width: 960px) {
  .growth-metrics {
    padding: 4rem 0;
  }

  .metric-value {
    font-size: 2.5rem;
  }
}

@media (max-width: 600px) {
  .growth-metrics {
    padding: 3rem 0;
  }

  .section-title {
    font-size: 2rem;
  }

  .metric-value {
    font-size: 2rem;
  }

  .metric-label {
    font-size: 1rem;
  }
}