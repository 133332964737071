/* src/components/LatestNewsTemplate.css */
.news-article {
    padding: 4rem 0;
    background-color: #f8f9fa;
    transition: background-color 0.3s ease;
  }
  
  .news-article:hover {
    background-color: #ffffff;
  }
  
  .article-title {
    color: #1976d2;
    margin-bottom: 1.5rem;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    transition: color 0.3s ease;
  }
  
  .article-title:hover {
    color: #1565c0;
  }
  
  .article-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 2.5rem;
    color: #666;
    font-size: 0.95rem;
  }
  
  .article-date, .article-author {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .article-image {
    margin-bottom: 2.5rem;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .article-image:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
  
  .article-image img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .article-image:hover img {
    transform: scale(1.05);
  }
  
  .article-divider {
    margin: 2.5rem 0;
    background-color: #e0e0e0;
    height: 2px;
    border: none;
  }
  
  .article-content {
    font-size: 1.15rem;
    line-height: 1.9;
    color: #333;
  }
  
  .article-content p {
    margin-bottom: 1.8rem;
  }
  
  .article-content h2 {
    font-size: 2rem;
    color: #1976d2;
    margin-top: 2.5rem;
    margin-bottom: 1.2rem;
  }
  
  .article-content h3 {
    font-size: 1.6rem;
    color: #333;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .article-content ul, .article-content ol {
    margin-bottom: 1.8rem;
    padding-left: 2.5rem;
  }
  
  .article-content li {
    margin-bottom: 0.8rem;
  }
  
  .article-content blockquote {
    font-style: italic;
    border-left: 4px solid #1976d2;
    padding: 1rem 1.5rem;
    margin: 2rem 0;
    color: #555;
    background-color: #f0f7ff;
    border-radius: 0 5px 5px 0;
  }
  
  .article-tags {
    display: flex;
    gap: 0.8rem;
    margin-top: 2.5rem;
    flex-wrap: wrap;
  }
  
  .article-tags .MuiChip-root {
    background-color: #e3f2fd;
    color: #1976d2;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    padding: 0.5rem 0.8rem;
  }
  
  .article-tags .MuiChip-root:hover {
    background-color: #1976d2;
    color: #ffffff;
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    .news-article {
      padding: 3rem 0;
    }
  
    .article-title {
      font-size: 2.4rem;
    }
  
    .article-content {
      font-size: 1.1rem;
    }
  
    .article-content h2 {
      font-size: 1.8rem;
    }
  
    .article-content h3 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .news-article {
      padding: 2rem 0;
    }
  
    .article-title {
      font-size: 2rem;
    }
  
    .article-content {
      font-size: 1rem;
    }
  
    .article-content h2 {
      font-size: 1.6rem;
    }
  
    .article-content h3 {
      font-size: 1.4rem;
    }
  
    .article-tags .MuiChip-root {
      font-size: 0.8rem;
    }
  }