/* src/components/Header.css */
.header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  color: #000000;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.5rem;
}

nav {
  display: flex;
  gap: 1rem;
}

nav a, nav button {
  color: #333333;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

nav a:hover, nav button:hover {
  color: #1976d2;
}

.services-menu, .industry-menu {
  margin-top: 0.5rem;
  z-index: 2;
}

.services-menu .MuiMenuItem-root,
.industry-menu .MuiMenuItem-root {
  padding: 0.5rem 1rem;
  min-width: 200px;
}

.services-menu .MuiMenuItem-root:hover,
.industry-menu .MuiMenuItem-root:hover {
  background-color: #f5f5f5;
}

.services-menu .MuiMenuItem-root .MuiSvgIcon-root,
.industry-menu .MuiMenuItem-root .MuiSvgIcon-root {
  margin-right: 0.5rem;
  font-size: 1rem;
  color: #2196f3;
}

.industry-menu {
  max-width: 600px;
  padding: 1rem;
}

@media (max-width: 960px) {
  .services-menu, .industry-menu {
    width: 100%;
    max-width: none;
  }
}