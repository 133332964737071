/* src/pages/industries/IndustryTemplate.css */
.solutions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .solution-paper {
    padding: 2rem;
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .solution-paper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #2196f3, #21d4fd);
  }
  
  .solution-paper:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  
  .solution-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .solution-paper ul {
    text-align: left;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .tech-chip {
    background-color: #e3f2fd;
    color: #2196f3;
    transition: all 0.3s ease-in-out;
    margin: 0.25rem;
  }
  
  .tech-chip:hover {
    background-color: #2196f3;
    color: #ffffff;
  }
  
  @media (max-width: 960px) {
    .solutions-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
  
  @media (max-width: 600px) {
    .solutions-grid {
      grid-template-columns: 1fr;
    }
  }