/* src/pages/services/ServicePage.css */

.service-page {
    padding: 4rem 0;
    background-color: #f8f9fa;
    background-image: 
      linear-gradient(rgba(255,255,255,.5) 2px, transparent 2px),
      linear-gradient(90deg, rgba(255,255,255,.5) 2px, transparent 2px),
      linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);
    background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  }
  
  .page-title {
    color: #1976d2;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .page-subtitle {
    color: #555;
    margin-bottom: 3rem;
  }
  
  .feature-card, .benefit-card {
    height: 100%;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  
  .feature-card::before, .benefit-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #2196f3, #21d4fd);
  }
  
  .feature-card:hover, .benefit-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  
  .feature-icon, .benefit-icon {
    font-size: 3rem;
    color: #2196f3;
    margin-bottom: 1rem;
    transition: all 0.3s ease-in-out;
  }
  
  .feature-card:hover .feature-icon, .benefit-card:hover .benefit-icon {
    transform: scale(1.1);
  }
  
  .feature-card h6, .benefit-card h6 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .feature-card p, .benefit-card p {
    color: #666;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .service-icon {
    font-size: 4rem;
    color: #1976d2;
    margin-right: 1rem;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .feature-card, .benefit-card {
    animation: fadeInUp 0.5s ease-out forwards;
    opacity: 0;
  }
  
  .feature-card:nth-child(1), .benefit-card:nth-child(1) { animation-delay: 0.1s; }
  .feature-card:nth-child(2), .benefit-card:nth-child(2) { animation-delay: 0.2s; }
  .feature-card:nth-child(3), .benefit-card:nth-child(3) { animation-delay: 0.3s; }
  
  .service-page a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.4s ease;
  }
  
  .service-page a:hover {
    color: #ffffff;
    text-decoration: underline;
  }
  
  .service-section {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }
  
  .service-section:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  
  .use-cases-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .use-cases-list li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .use-cases-list .MuiSvgIcon-root {
    margin-right: 1rem;
    color: #1976d2;
  }
  
  .service-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .service-content h2 {
    color: #1976d2;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .service-content h3 {
    color: #333;
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .service-content p {
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  .service-content ol {
    list-style-type: none;
    counter-reset: item;
    padding-left: 0;
  }
  
  .service-content ol li {
    counter-increment: item;
    margin-bottom: 1rem;
    padding-left: 2rem;
    position: relative;
  }
  
  .service-content ol li:before {
    content: counter(item);
    background: #1976d2;
    border-radius: 50%;
    color: white;
    width: 1.5em;
    height: 1.5em;
    font-weight: bold;
    font-size: 0.8em;
    text-align: center;
    display: inline-block;
    line-height: 1.5em;
    margin-right: 0.5em;
    position: absolute;
    left: 0;
    top: 0.2em;
  }
  
  .service-content ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .service-content ul li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .service-content ul li:before {
    content: '•';
    color: #1976d2;
    font-weight: bold;
    font-size: 1.2em;
    position: absolute;
    left: 0;
    top: -0.1em;
  }
  
  .call-to-action {
    text-align: center;
    margin-top: 3rem;
  }
  
  .call-to-action button {
    background-color: #0080ff;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }
  
  .call-to-action button:hover {
    background-color: #ffffff;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 768px) {
    .service-page {
      padding: 2rem 0;
    }
  
    .page-title {
      font-size: 2rem;
    }
  
    .page-subtitle {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
  
    .feature-card, .benefit-card {
      padding: 1.5rem;
    }
  
    .service-content {
      padding: 1.5rem;
    }
  
    .service-content h2 {
      font-size: 2rem;
    }
  
    .service-content h3 {
      font-size: 1.5rem;
    }
  
    .service-content p, .service-content li {
      font-size: 1rem;
    }
  }

  .page-subtitle {
    color: #555;
    margin-bottom: 3rem;
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;
    padding-bottom: 2rem;
  }
  
  .page-subtitle::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: linear-gradient(90deg, #2196f3, #21d4fd);
    border-radius: 2px;
  }
  
  @media (max-width: 768px) {
    .page-subtitle {
      font-size: 1.1rem;
      padding-bottom: 1.5rem;
    }
  }