.solutions {
    padding: 80px 0;
    background-color: #f8f9fa;
  }
  
  .section-title {
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    font-weight: 700;
  }
  
  .solution-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    transition: all 0.3s ease;
  }
  
  .solution-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .solution-icon {
    font-size: 3rem;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 20px;
    color: white;
  }
  
  .solution-title {
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
  }
  
  .solution-description {
    margin-bottom: 20px;
    color: #666;
    flex-grow: 1;
  }
  
  .solution-features {
    margin-bottom: 20px;
  }
  
  .solution-features ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .solution-features li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
  }
  
  .solution-features li::before {
    content: "•";
    color: #1976d2;
    font-weight: bold;
    position: absolute;
    left: 0;
  }
  
  .solution-button {
    align-self: flex-start;
    margin-top: auto;
  }
  
  @media (max-width: 960px) {
    .solutions {
      padding: 60px 0;
    }
  }
  
  @media (max-width: 600px) {
    .solutions {
      padding: 40px 0;
    }
  
    .section-title {
      font-size: 2rem;
    }
  
    .solution-card {
      padding: 20px;
    }
  
    .solution-title {
      font-size: 1.5rem;
    }
  }

  .solution-icon {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 1rem;
  }