/* src/components/GrowthMetrics.css */
.growth-metrics {
    padding: 80px 0;
    background-color: #f8f9fa;
  }
  
  .section-title {
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    font-weight: 700;
  }
  
  .metric-paper {
    padding: 30px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .metric-paper:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  
  .metric-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .metric-value {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1976d2;
    margin-bottom: 10px;
  }
  
  .metric-label {
    font-size: 1.1rem;
    color: #666;
  }
  
  @media (max-width: 960px) {
    .growth-metrics {
      padding: 60px 0;
    }
  }
  
  @media (max-width: 600px) {
    .growth-metrics {
      padding: 40px 0;
    }
  
    .metric-value {
      font-size: 2rem;
    }
  
    .metric-label {
      font-size: 1rem;
    }
  }