.values-container {
    padding: 60px 0;
    background-color: #f8f9fa;
  }
  
  .values-header {
    margin-bottom: 40px;
    color: #333;
    font-weight: 700;
  }
  
  .value-item {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 30px;
    height: 100%;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .value-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .icon-container {
    background-color: #e3f2fd;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .animated-icon {
    width: 60px;
    height: 60px;
  }
  
  .value-title {
    margin-bottom: 15px !important;
    color: #333;
    font-weight: 600 !important;
  }
  
  .value-description {
    color: #666;
    line-height: 1.6 !important;
  }
  
  