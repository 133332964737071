.achievements {
  padding: 6rem 0;
  background-color: #f8f9fa;
}

.achievement-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.achievement-paper {
  padding: 2rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.achievement-paper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #2196f3, #21d4fd);
}

.achievement-paper:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.achievement-icon {
  font-size: 3rem;
  color: #2196f3;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.achievement-icon .animated-icon {
  width: 80px;
  height: 80px;
}

.achievement-paper:hover .achievement-icon {
  transform: scale(1.1);
}

.achievement-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.achievement-description {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .achievement-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}