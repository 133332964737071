/* src/pages/FinTechInnovation.css */
.fintech-innovation {
  padding: 6rem 0;
  background-color: #f5f5f5;
}

.page-title {
  color: #004d40;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
}

.page-subtitle {
  color: #00695c;
  text-align: center;
  margin-bottom: 3rem;
}

.service-card {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #009688, #4db6ac);
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.service-icon {
  font-size: 3rem;
  color: #00897b;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.service-card:hover .service-icon {
  transform: scale(1.1);
}

.fintech-solution {
  margin-top: 4rem;
  background-color: #e0f2f1;
  padding: 2rem;
  border-radius: 10px;
}

.solution-title {
  color: #004d40;
  margin-bottom: 1rem;
}

.solution-description {
  color: #00695c;
}