/* src/pages/ResearchDevelopment.css */
.research-development {
  padding: 6rem 0;
  background-color: #f8f9fa;
}

.page-title {
  color: #2c3e50;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
}

.page-subtitle {
  color: #34495e;
  text-align: center;
  margin-bottom: 3rem;
}

.area-card {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.area-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #3498db, #2980b9);
}

.area-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.area-icon {
  font-size: 3rem;
  color: #3498db;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.area-card:hover .area-icon {
  transform: scale(1.1);
}

.research-project {
  margin-top: 4rem;
  background-color: #ecf0f1;
  padding: 2rem;
  border-radius: 10px;
}

.project-title {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.project-description {
  color: #34495e;
}