/* TechExpertiseSection.css */

.tech-expertise-section {
    background-color: #f8f9fa;
    padding: 6rem 0;
    overflow: hidden;
  }
  
  .section-title {
    color: #1976d2;
    margin-bottom: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .section-subtitle {
    color: #555;
    margin-bottom: 3rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tech-category-card {
    height: 100%;
    padding: 2rem;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    border-radius: 15px !important;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff !important;
  }
  
  .tech-category-card:hover {
    transform: translateY(-10px);
  }
  
  .category-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .category-icon {
    background-color: #e3f2fd;
    color: #1976d2;
    margin-right: 1rem;
    padding: 10px;
    border-radius: 50%;
  }
  
  .category-title {
    color: #333;
    font-weight: 600;
    flex-grow: 1;
  }
  
  .tech-chips-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .tech-chip {
    background-color: #e3f2fd;
    color: #1976d2;
    font-weight: 500;
    transition: all 0.3s ease;
    border-radius: 20px;
    padding: 5px 10px;
  }
  
  .tech-chip:hover {
    background-color: #1976d2;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .expand-button {
    align-self: flex-end;
    color: #1976d2;
    cursor: pointer;
    font-weight: 500;
    margin-top: auto;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .expand-button:hover {
    background-color: #e3f2fd;
  }
  
  @media (max-width: 960px) {
    .tech-expertise-section {
      padding: 4rem 0;
    }
  
    .section-title {
      font-size: 2.5rem;
    }
  
    .section-subtitle {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 600px) {
    .tech-expertise-section {
      padding: 3rem 0;
    }
  
    .section-title {
      font-size: 2rem;
    }
  
    .section-subtitle {
      font-size: 1rem;
    }
  
    .category-title {
      font-size: 1.2rem;
    }
  
    .tech-category-card {
      padding: 1.5rem;
    }
  }