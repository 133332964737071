/* CaseStudies.css */

.case-studies {
    padding: 6rem 0;
    background-color: #f8f9fa;
  }
  
  .section-title {
    color: #1976d2;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .section-subtitle {
    color: #555;
    text-align: center;
    margin-bottom: 3rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .case-study-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    border-radius: 15px !important;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1) !important;
  }
  
  .case-study-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2) !important;
  }
  
  .case-study-title {
    color: #1976d2;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .case-study-client {
    color: #555;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .case-study-result {
    color: #333;
    font-weight: 400;
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .result-label {
    font-weight: 600;
    color: #1976d2;
  }
  
  .case-study-actions {
    margin-top: auto;
  }
  
  .case-study-button {
    background-color: #e3f2fd !important;
    color: #1976d2 !important;
    transition: all 0.3s ease !important;
    text-transform: none !important;
    font-weight: 500 !important;
  }
  
  .case-study-button:hover {
    background-color: #bbdefb !important;
    transform: translateY(-2px);
  }
  
  @media (max-width: 960px) {
    .case-studies {
      padding: 4rem 0;
    }
  
    .section-title {
      font-size: 2.5rem;
    }
  
    .section-subtitle {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 600px) {
    .case-studies {
      padding: 3rem 0;
    }
  
    .section-title {
      font-size: 2rem;
    }
  
    .section-subtitle {
      font-size: 1rem;
    }
  
    .case-study-title {
      font-size: 1.2rem;
    }
  }