/* TechProcess.css */
.tech-process {
  padding: 3rem 0;
  background-color: #f8f9fa;
  overflow: hidden;
}

.header-container {
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
}

.section-title {
  color: #1976d2;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.2;
}

.highlight {
  color: #ff6b6b;
  position: relative;
  display: inline-block;
}

.highlight::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 3px;
  background-color: #ff6b6b;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.highlight:hover::after {
  transform: scaleX(1);
}

.section-subtitle {
  color: #555;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.2rem;
  line-height: 1.8;
}

.decorative-line {
  width: 100px;
  height: 4px;
  background-color: #1976d2;
  margin: 0 auto;
}

.process-timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.process-timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #e0e0e0;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  border-radius: 3px;
}

.timeline-item {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  margin-bottom: 60px;
}

.timeline-item::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #1976d2;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #fff;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #fff;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.right::after {
  left: -16px;
}

.timeline-content {
  padding: 30px;
  background-color: white;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.timeline-content:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0,0,0,0.15);
}

.timeline-icon {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.step-title {
  color: #1976d2;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.step-description {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.6;
}

.tools-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 1rem;
}

.tool-chip {
  background-color: #e3f2fd;
  color: #1976d2;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.tool-chip:hover {
  background-color: #1976d2;
  color: white;
}

@media screen and (max-width: 960px) {
  .process-timeline::after {
    left: 31px;
  }
  
  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  
  .timeline-item::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  .left::after, .right::after {
    left: 15px;
  }

  .right {
    left: 0%;
  }

  .timeline-content {
    padding: 20px;
  }

  .timeline-icon {
    left: 10px;
    transform: none;
  }
}

@media screen and (max-width: 600px) {
  .tech-process {
    padding: 4rem 0;
  }

  .section-title {
    font-size: 2.5rem;
  }

  .section-subtitle {
    font-size: 1rem;
    line-height: 1.6;
  }

  .step-title {
    font-size: 1.2rem;
  }

  .step-description {
    font-size: 0.9rem;
  }

  .tool-chip {
    font-size: 0.7rem;
  }
}