/* src/pages/AIMLEngineering.css */
.aiml-engineering {
  padding: 6rem 0;
  background-color: #f0f8ff;
}

.page-title {
  color: #1a237e;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
}

.page-subtitle {
  color: #303f9f;
  text-align: center;
  margin-bottom: 3rem;
}

.service-card {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #3f51b5, #5c6bc0);
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.service-icon {
  font-size: 3rem;
  color: #3f51b5;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.service-card:hover .service-icon {
  transform: scale(1.1);
}

.case-study {
  margin-top: 4rem;
  background-color: #e8eaf6;
  padding: 2rem;
  border-radius: 10px;
}

.case-study-title {
  color: #1a237e;
  margin-bottom: 1rem;
}

.case-study-description {
  color: #283593;
}