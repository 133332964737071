/* src/pages/About.css */
.about {
  padding: 6rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.section-title {
  text-align: center;
  color: #0b2a63;
  margin-bottom: 3rem;
  font-size: 2.8rem;
  font-weight: 700;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #1976d2;
}

.section-subtitle {
  color: #1976d2;
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}

.about-intro {
  margin-bottom: 5rem;
}

.about-description {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.7;
  text-align: justify;
}

.office-image {
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: block;
  margin: 0 auto;
}

.office-image:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.stats-container {
  margin-top: 2.5rem;
}

.stat-card {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.stat-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, #1976d2, #64b5f6);
}

.stat-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
}

.stat-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-content .MuiSvgIcon-root {
  font-size: 3rem;
  color: #1976d2;
  margin-bottom: 1.2rem;
  transition: transform 0.3s ease-in-out;
}

.stat-card:hover .MuiSvgIcon-root {
  transform: scale(1.15);
}

.stat-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1976d2;
  margin-bottom: 0.4rem;
}

.stat-label {
  font-size: 1.1rem;
  color: #555;
  font-weight: 500;
}

.milestone-card {
  padding: 1.2rem;
  text-align: center;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}

.milestone-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.milestone-image {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.milestone-year {
  color: #1976d2;
  font-weight: 600;
  margin-bottom: 0.4rem;
  font-size: 1.1rem;
}

.milestone-event {
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
  font-weight: 600;
}

.milestone-description {
  font-size: 0.9rem;
  color: #666;
}

.team-member-card {
  text-align: center;
  padding: 1.8rem;
  transition: all 0.3s ease-in-out;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.team-member-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, #1976d2, #64b5f6);
}

.team-member-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

.team-member-avatar {
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto 1.2rem;
  border: 3px solid #e3f2fd;
  transition: all 0.3s ease-in-out;
}

.team-member-card:hover .team-member-avatar {
  transform: scale(1.08);
  border-color: #1976d2;
}

.MuiTimeline-root {
  margin-top: 2.5rem;
}

.MuiTimelineItem-missingOppositeContent:before {
  flex: 0;
  padding: 0;
}

@media (max-width: 960px) {
  .about {
    padding: 4rem 0;
  }

  .section-title {
    font-size: 2.4rem;
  }

  .section-subtitle {
    font-size: 1.8rem;
  }

  .about-description {
    font-size: 1rem;
  }

  .stat-card {
    padding: 1.8rem;
  }

  .stat-value {
    font-size: 2.2rem;
  }

  .milestone-image {
    max-width: 180px;
  }
}

@media (max-width: 600px) {
  .about {
    padding: 3rem 0;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-subtitle {
    font-size: 1.6rem;
  }

  .stat-card {
    padding: 1.5rem;
  }

  .stat-value {
    font-size: 1.8rem;
  }

  .team-member-avatar {
    width: 80px !important;
    height: 80px !important;
  }

  .milestone-image {
    max-width: 150px;
  }
}